import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar")!

  return (_openBlock(), _createBlock(_component_van_nav_bar, {
    fixed: "",
    title: _ctx.$route.meta.title,
    "left-text": _ctx.$route.meta.isBar ? '退出' : '返回',
    "left-arrow": !_ctx.$route.meta.isBar,
    onClickLeft: _ctx.onClickLeft
  }, null, 8, ["title", "left-text", "left-arrow", "onClickLeft"]))
}