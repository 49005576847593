import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'HomeView',
    component: () => import('@/views/HomeView.vue'),
    meta: { title: '学习', isBar: true }
  },
  {
    path: '/word',
    name: 'WordView',
    component: () => import('@/views/word/WordView.vue'),
    meta: { title: '识字', isBar: false }
  },
  {
    path: '/word/list/:id',
    name: 'WordList',
    component: () => import('@/views/word/WordList.vue'),
    meta: { title: '识字', isBar: false }
  },
  {
    path: '/wordwrite',
    name: 'WordWrite',
    component: () => import('@/views/wordwrite/WriteList.vue'),
    meta: { title: '听写', isBar: false }
  },
  {
    path: '/wordwrite/info/:id',
    name: 'WriteInfo',
    component: () => import('@/views/wordwrite/WriteInfo.vue'),
    meta: { title: '听写', isBar: false }
  },
  {
    path: '/poem',
    name: 'PoemView',
    component: () => import('@/views/poem/PoemView.vue'),
    meta: { title: '古诗词', isBar: false }
  },
  {
    path: '/poem/info/:id',
    name: 'PoemInfo',
    component: () => import('@/views/poem/PoemInfo.vue'),
    meta: { title: '古诗词', isBar: false }
  },
  {
    path: '/zuowen',
    name: 'ZuowenList',
    component: () => import('@/views/zuowen/ZuowenList.vue'),
    meta: { title: '作文鉴赏', isBar: false }
  },
  {
    path: '/zuowen/info/:id',
    name: 'ZuowenInfo',
    component: () => import('@/views/zuowen/ZuowenInfo.vue'),
    meta: { title: '作文鉴赏', isBar: false }
  },
  {
    path: '/reading',
    name: 'ReadingList',
    component: () => import('@/views/reading/ReadingList.vue'),
    meta: { title: '阅读理解', isBar: false }
  },
  {
    path: '/reading/info/:id',
    name: 'ReadingInfo',
    component: () => import('@/views/reading/ReadingInfo.vue'),
    meta: { title: '阅读理解', isBar: false }
  },
  {
    path: '/idiom',
    name: 'IdiomView',
    component: () => import('@/views/idiom/IdiomView.vue'),
    meta: { title: '成语', isBar: false }
  },
  {
    path: '/words',
    name: 'WordsView',
    component: () => import('@/views/words/WordsView.vue'),
    meta: { title: '近反义词', isBar: false }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
