
import { defineComponent } from 'vue';
import { NavBar } from 'vant';
import { useRoute } from 'vue-router';
import { removeToken } from '@/utils/checkToken';

export default defineComponent({
  name: 'NavBar',
  components: {
    [NavBar.name]: NavBar
  },
  setup() {
    const route = useRoute()
    const onClickLeft = () => {
      if (route.meta.isBar) {
        removeToken()
        window.parent.parent.postMessage(-1, '*')
      } else {
        history.back()
      }
    }
    return {
      onClickLeft,
    }
  }
});
